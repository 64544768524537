import React from 'react';
import ChromeReaderModeRoundedIcon from '@material-ui/icons/ChromeReaderModeRounded';
import MusicNoteRoundedIcon from '@material-ui/icons/MusicNoteRounded';
import StraightenIconRounded from '@material-ui/icons/StraightenRounded';
import CreateIconRounded from '@material-ui/icons/CreateRounded';
import { Typography, Container, Button } from '@material-ui/core';
import { Link } from 'gatsby';

import Header from '../components/NavigationBar';
import Footer from '../components/Footer';
import BackgroundImage from '../components/BackgroundImage';

import {
  HYMNS,
  COMPOSERS,
  TUNES,
  AUTHORS,
} from '../globals';

const Home = () => {
  const infoTabs = [
    {
      Icon: ChromeReaderModeRoundedIcon,
      text: 'Hymns',
      link: `/${HYMNS}`,
    },
    {
      Icon: CreateIconRounded,
      text: 'Authors',
      link: `/${AUTHORS}`,
    },
    {
      Icon: MusicNoteRoundedIcon,
      text: 'Tunes',
      link: `/${TUNES}`,
    },
    {
      Icon: StraightenIconRounded,
      text: 'Composers',
      link: `/${COMPOSERS}`,
    },
  ];

  return (
    <>
      <Header position="fixed" contained />
      <main>
        <BackgroundImage src="spiritual-songs-hymns" className="home-hero">
          <Container maxWidth="md" className="home-hero__content">
            <Typography component="h1" className="home-hero__title">A Collection of Hymns</Typography>
            <Typography component="h2" className="home-hero__sub-text">
              Celebrating hymns and tunes associated with the
              {' '}
              <span className="u-txt--italic">Hymns for the Little Flock</span>
              {' '}
              tradition
            </Typography>
            <Link to={`/${HYMNS}`} className="no-underline">
              <Button variant="contained" size="large" color="primary">
                Browse hymns
              </Button>
            </Link>
          </Container>
        </BackgroundImage>

        <section className="u-padding-t--xxlrg u-padding-b--xxlrg clearfix u-bg--grey">
          <Container maxWidth="lg">
            <Typography component="p" className="u-margin-b">
              The intitial aim of this site is to facilitate a revision
              of the selection of tunes used in the Music Edition of Psalms
              and Hymns and Spiritual Songs (1981). We hope that this will
              be a collaborative project so that any who are interested can
              provide feedback and suggestions as the project progresses.
            </Typography>
            <Typography component="p">
              In addition, the site aims to help users become familiar with
              the tunes, learn the various SATB parts, and to provide a reliable
              source of historical information about the hymns, authors, tunes,
              and composers, as well as previous editions of the Little Flock hymnbooks.
            </Typography>

            <ul className="u-list-style-none u-margin-t--xlrg">

              {infoTabs.map(({ Icon, text, link }, i) => (
                <li className="home-card-container" key={i}>
                  <div className="card">
                    <div className="card__content">
                      <div className="card__header">
                        <Icon className="card__icon" style={{ fontSize: '30px' }} />
                        <Typography component="h6" variant="h6" className="card__title">
                          {text}
                        </Typography>
                      </div>
                    </div>
                    <Link className="u-abs-cover" to={link} />
                  </div>
                </li>
              ))}

            </ul>
          </Container>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Home;
