import React from 'react';
import { Container } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'gatsby';

import {
  HYMNS,
  AUTHORS,
  COMPOSERS,
  TUNES,
} from '../globals';

const Footer = () => {
  const year = moment().format('YYYY');

  const navItems = [
    { text: 'Hymns', to: `/${HYMNS}` },
    { text: 'Authors', to: `/${AUTHORS}` },
    { text: 'Composers', to: `/${COMPOSERS}` },
    { text: 'Tunes', to: `/${TUNES}` },
  ];

  return (
    <footer className="footer">
      <Container maxWidth="lg">
        <div className="footer__content">
          {`\u00A9 ${year} Spiritual Songs`}
          <nav>
            <ul className="u-list-style-none footer__nav-items">

              {navItems.map(({ text, to }, i) => (
                <li className="footer__nav-item" key={i}>
                  <span className="u-relative">
                    {text}
                    <Link className="u-abs-cover" to={to} />
                  </span>
                </li>
              ))}

            </ul>
          </nav>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
