import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

const BackgroundSection = ({
  className,
  children,
  backgroundColor,
  tag,
  src,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allCloudinaryAsset {
        edges {
          node {
            fluid {
              ...CloudinaryAssetFluid
              ...CloudinaryAssetFluidLimitPresentationSize
            }
          }
        }
      }
      site {
        siteMetadata {
          colours {
            primary
          }
        }
      }
    }
  `);

  const file = data.allCloudinaryAsset.edges.find(({ node }) => node.fluid.src.endsWith(src));

  if (!file) return null;

  return (
    <BackgroundImage
      Tag={tag}
      className={className}
      fluid={file.node.fluid}
      backgroundColor={backgroundColor || data.site.siteMetadata.colours.silver}
    >
      {children}
    </BackgroundImage>
  );
};

BackgroundSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
    PropTypes.array,
  ]),
  backgroundColor: PropTypes.string,
  tag: PropTypes.string,
  src: PropTypes.string,
};

BackgroundSection.defaultProps = {
  className: undefined,
  children: undefined,
  backgroundColor: undefined,
  tag: 'section',
  src: 'no-image',
};

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default StyledBackgroundSection;
